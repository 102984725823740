<template lang="html">
    <div class="container-fluid p-5">
        <loading :active.sync="isLoading" :is-full-page="fullPage"></loading>
        <div class="row">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-header">
                        <div class="row">
                            <div class="col-md-2">နေ့စဥ်စာရင်း အားလုံးပေါင်း</div>
                            <div class="col-md-2">
                                <input type="month" v-model="month" class="form-control" @blur="fetchData()">
                            </div>
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="table-responsive">
                                    <table class="table table-bordered">
                                        <thead>
                                            <tr>
                                                <th class="th-width-100">ရက်စွဲ</th>
                                                <th >အပေါင်ခုရေ</th>
                                                <th class="th-width-100">ချေးငွေ</th>
                                                <th >အရွေးခုရေ</th>
                                                <th class="th-width-100">ချေးငွေအရင်း</th>
                                                <th class="th-width-100">အတိုး</th>
                                                <th class="th-width-100">တိုးရင်းပေါင်း</th>
                                                <th class="th-width-100">ပိုငွေ / စိုက်ငွေ</th>
                                                <th class="th-width-100">လက်ကျန်</th>
                                                <th class="th-width-400">အထည် ပိုငွေ/စိုက်ရငွေ</th>
                                                <th class="th-width-400">ရုံးချုပ်မှ ထုတ်ငွေ/ အပ်ငွေ</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr class="bg-primary">
                                                <td></td>
                                                <td>{{metaList.totalMortgageCount}}</td>
                                                <td>{{metaList.totalLoan}}</td>
                                                <td>{{metaList.payCount}}</td>
                                                <td>{{metaList.payLoan}}</td>
                                                <td>{{metaList.interest}}</td>
                                                <td>{{metaList.loanInterest}}</td>
                                                <td>{{metaList.gold}}</td>
                                                <td></td>
                                                <td>{{metaList.other}}</td>
                                                 <td>{{metaList.office}}</td>
                                            </tr>
                                            <tr v-for="(data,index) in dataList" v-if="data">
                                                <td>{{data.date}}</td>
                                                <td>{{data.mortgageQunatity}}</td>
                                                <td>{{data.mortgageLoan}}</td>
                                                <td>{{data.payQuantity}}</td>
                                                <td>{{data.payLoan}}</td>
                                                <td>{{data.payInterest}}</td>
                                                <td>{{data.payLoanInterest}}</td>
                                                <td v-bind:class="{'text-danger': data.goldSign === 'm'}">{{data.gold}}</td>
                                                <td>{{data.remain}}</td>
                                                <td>
                                                    <div class="row">
                                                        <div class="col-md-5">
                                                            <select class="form-control" v-model="data.otherSign" disabled>
                                                                <option value="p">ပိုငွေ</option>
                                                                <option value="m">စိုက်ရငွေ</option>
                                                        </select>
                                                        </div>
                                                        <div class="col-md-7">
                                                            <input type="number" class="form-control" v-model="data.other" disabled>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div class="row">
                                                        <div class="col-md-5">
                                                             <select class="form-control" v-model="data.officeSign" disabled>
                                                            <option value="p">ထုတ်ငွေ</option>
                                                            <option value="m">အပ်ငွေ</option>
                                                        </select>
                                                        </div>
                                                        <div class="col-md-7">
                                                           <input type="number" class="form-control" v-model="data.office" disabled>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr v-if="dataList.length <= 0">
                                                <td colspan="11" class="text-center">Empty List</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <b-modal id="bv-modal-delete" ref="my-modal" title="သတိပေးချက်" hide-footer>
            <p class="my-4">ရွေးလို့မရပါ။</p>
        </b-modal>
    </div>
</template>

<script lang="js">
    import {
        mapState,
        mapActions
    } from 'vuex'
    import Loading from 'vue-loading-overlay'
    import 'vue-loading-overlay/dist/vue-loading.css';
    import moment from 'moment';
    export default {
        name: 'reportList',
        components: {
            Loading
        },
        data() {
            return {
                isLoading: false,
                fullPage: true,
                alert: "",
                id: '',
                month: '',
                dataList: [],
                metaList: []
            }
        },
        watch: {
            '$route' (to, from) {
                this.fetchData()
            }
        },
        computed: {},
        methods: {
            ...mapActions({
                dailyReportListAction: 'dailyReportListAction',
                dailyReportSaveAction: 'dailyReportSaveAction',
            }),
            async fetchData() {
                this.isLoading = true
                let current = moment(new Date()).format('YYYY-MM')
                if(this.month > current){
                    this.isLoading = false;
                    this.$refs['my-modal'].show()
                    return
                }
                let option = {
                    type: "all",
                    month: this.month,
                }
                await this.dailyReportListAction({
                    ...option
                }).then(res => {
                    if (res.status == "success") {
                        this.dataList = res.data.data.data
                        this.metaList = res.data.data.meta
                        this.$router.replace({ path: 'daily-report-list', query: { month: this.month} }).catch(()=>{})
                        this.isLoading = false;
                    }
                }).catch(err => this.isLoading = true)
            },
            async saveData(data) {
                this.isLoading = true
                let option = {
                    saveData: data,
                }
                await this.dailyReportSaveAction({
                    ...option
                }).then(res => {
                    if (res.status == "success") {
                        this.fetchData()
                        this.isLoading = false;
                    }
                }).catch(err => this.isLoading = true)
            }

        },
        mounted() {
            this.month = this.$route.query.month
            this.fetchData()
        }
    }
</script>

<style scoped lang="scss">
    .action {
        border: none;
        background: none;
        font-size: 24px;
    }
</style>